export const ErpInvoiceType = Object.freeze({
  32: "common.rentalsReservationInvoiced",
  33: "common.rentalsReservationCancelledOwnerResponsability",
  34: "common.rentalsReservationCancelledTenantResponsability",
  36: "common.rentalsOwnerPaymentInvoiced",
  71: "common.creditNoteRentalsReservationInvoiced",
  72: "common.creditNoteRentalsReservationCancelledOwnerResponsibility",
  73: "common.creditNoteRentalsReservationCancelledTenantResponsibility",
  74: "common.creditNoteRentalsOwnerPaymentInvoiced",
  83: "common.manualInvoiceRentals",
  84: "common.creditNoteManualInvoiceRentals",
  91: "common.rentalTenantInvoice",
  94: "common.rentalsDeposit",
  95: "common.rentalSupplyManagement",
  96: "common.rentalTenantChargeRemittance",
  97: "common.rentalIrregulars",
  101: "common.rentalsItpManagement",
  102: "common.creditNoteRentalsItpManagement",
  103: "common.remittanceManagementFee",
  104: "common.remittanceManagementFeeCreditNote",
});
